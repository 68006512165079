import Home from '../pages/Home'
import Asset from '../pages/Asset'

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/asset',
    name: 'Asset',
    component: Asset
  }
]
