<template>
  <div class="flex flex-col items-center bg-black pb-24 px-4 md:px-0">
    <div class="title text-xl font-black text-primaryAsset mt-12 md:mt-24">BONUS</div>
    <div class="max-w-xl font-normal mt-4 text-center">To present our philosophy, we have created original renderings showing our most important elements of the product.</div>
    <div id="yinyangchi" class="font-black text-2xl md:text-5xl mt-12">YIN & YANG & CHI</div>
    <div class="flex flex-row my-12 max-w-6xl mx-auto justify-between">
      <img class="w-1/3 md:w-64 md:h-64" src="../../assets/images1/introduction/YIN.png" />
      <img class="w-1/3 lg:mx-12 md:w-64 md:h-64" src="../../assets/images1/introduction/YANG.png" />
      <img class="w-1/3 md:w-64 md:h-64" src="../../assets/images1/introduction/CHI.png" />
    </div>
    <a class="download-icon secondary-button-right text-xs px-8 py-3 hover:text-white flex flex-row" id="logo-asset-pack" href="/download/YIN&YANG&CHI.zip" download>
      <img id="download_icon" class="img1" src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAA7UlEQVQ4je3UPyvFcRTH8Rf3UspNKMUsi8nsAZgNGJXRIk/BIzCw2G7d7RYpj8BuUDbJIMniTxkYRKf7VTdH7o/J4D19+5zT+/s7nW+/vtO9eT24QBNb37X1pyQzjrGU/kJUiX/RHxDFuu/xhhFslHMrdfYQPeI8pZykpEs0kFJesYKHruwI26mT+ofoFuupzCXWyvkKq2W8zxxjIWyjmEjlDgdYxhnuUrXDFBr1FGfaKfmCGO0Z07lUiSFM4ilEh1jC4g8f6DB2MBgbjtE2MYf98nUvFUWNcvFu/LNCdINZzJSHWKsoigtjm9fwDvXEJbxGU9J5AAAAAElFTkSuQmCC" />
      <img id="download_icon_white" class="img2" width="18" height="18" src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAA60lEQVQ4je3UP0pDQRDH8U9ED5BC4gEUu2AqS1v/NFZqbiB4BLH0HhY2gkUaxdILiBYK3kDBUkxjQBncwIMJ78UUVv5gi5n58d2d3fem9T781KCLUt6vs82nTFYnZSZoLqdm0z/oD0F1z3+J5bJGuMUL+snZAFpBtxJv4DG5iupaiy/5oxIPcZBcFVD8AtupwjMOK/ERnpKLrWBEa3ul9+tk4RyrZcOzVP3RejDq7misk5SZoNjpCwu5NLXawYgxcoclrOHtl5BFPOA1QJu4igADNA6oouhitxxiZzzYAnaKHlpTguJK7nGMm29AOSu6Np7wYQAAAABJRU5ErkJggg==" />
      <div class="ml-2">Asset Pack</div>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'Images',
  }
</script>

<style lang="scss" scoped>
  .title {
    letter-spacing: 0.5rem;
    font-size: 30px;
  }

  #logo-asset-pack {
    outline: none;
    border-radius: 2rem;
    text-align: center;
    border: 1px solid #C99B4D;
  }
  .download-icon {
    &:hover {
      background: #C99B4D;

      .img1 {
        display: none;
      }

      .img2 {
        display: block;
      }
    }
  }

  .img1 {
    display: block;
  }

  .img2 {
    display: none;
  }
</style>