export default {
  mechanism: 'Mechanisms',
  tokenomics: 'Tokenomics',
  grand: 'Grand',
  master: 'Master',
  about: 'ABOUT',
  about1: 'About',
  ofYin: 'of YIN',
  automaticallyReInvested: 'Automatically Re-Invested',
  automaticallyReInvestedDescription: 'Uniswap v3 requires users to manually collect fees in order to re-invest. YIN however collects fees and re-invests for users automatically.',
  banner1: 'The Solution',
  banner2: 'to ',
  banner3: 'Liquidity',
  banner4: 'for Decentralized Finance.',
  coinGecko: 'CoinGecko',
  coinMarketCap: 'Coin Market Cap',
  contactUs: 'Contact Us',
  contacts: 'CONTACTS',
  disclaimer: 'YIN Protocol Disclaimer',
  disclaimerWebsite: 'Disclaimer Website',
  doc: 'Tokenomics Doc',
  email: 'contact@yin.finance',
  getCoverage: 'Get Coverage',
  goToVaults: 'Go to vaults',
  grandMasterDescription: 'Grand Master is a protocol for leveraging your position in our liquidity pool. Asset lenders can earn high interest on their assets and lenders can earn even higher transaction fees and farming APY from taking on leveraged positions in yield farming.',
  home: 'HOME',
  home1: 'Home',
  "Coming soon": "Coming soon",
  investors: 'Backed by Global Investors',
  launchApp: 'Launch App',
  liquidityManagement: 'Active Liquidity Management',
  liquidityManagementDescription: 'Users can choose to subscribe to various CHI that are available via their personal Smart Vaults.',
  upcoming: "Upcoming",
  yinProducts: "YIN products",
  pages: 'PAGES',
  position: 'Untere Rainstrasse 64 6340 Baar Switzerland',
  poweredBy: 'Powered by Nexus Mutual',
  privacy: 'Privacy Policy',
  subscribe: 'Subscribe to our newsletter for updates',
  tokenomicsDescription: 'YIN tokenomics are live, meaning YIN token holders can stake their YIN to earn revenue from the YIN ecosystem and earn redemption fees.',
  uniswap: 'Uniswap',
  yin: 'YIN',
  yieldMining: 'Multi-Party Yield Mining',
  yieldMiningDescription: 'Managing liquidity in YIN not only earns fees and additional YIN for users, but also allows you to access Uniswap v3 mining cooperatives audited by YIN for reliable so users can reap even more benefits.',
  getInTouch: 'Get in Touch',
  stayUpdated: '& Stay updated',
  information: 'Information'
}
