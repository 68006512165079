import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '../locales'
import Cookies from 'js-cookie'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: Cookies.get('locale') || 'en',
  messages: locales,
  fallbackLocale: 'en',
  silentTranslationWarn: true,
})

export default i18n
