<template>
  <div class="relative max-w-6xl mx-auto">
    <div class="absolute w-full flex flex-row items-center pt-4 md:pt-8 justify-between">
      <img class="h-8 md:h-12 ml-4 md:ml-0" src="../../assets/images1/icons/YINLogo.svg">
      <div class="flex-1 flex-row justify-center hidden md:flex text-15">
        <a class="mr-12 opacity-75 hover:opacity-100" href="#logo">LOGO</a>
        <a class="mr-12 opacity-75 hover:opacity-100" href="#colors">COLORS</a>
        <a class="mr-12 opacity-75 hover:opacity-100" href="#fonts">FONTS</a>
        <div class="flex flex-col items-center">
          <div class="bg-primary bonus rounded-full text-xs px-2 bonus">BONUS</div>
          <a class="opacity-75 hover:opacity-100" href="#yinyangchi">YIN&YANG&CHI</a>
        </div>

      </div>
      <a class="bg-primary hover:bg-primary-h ml-12 px-8 py-3 rounded-full hidden md:block primary-button">Contact Us</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Header',
  }
</script>

<style scoped>
  .bonus {
    letter-spacing: 0.2rem;
    line-height: 1.2rem;
    margin-top: -1.2rem;
  }

  .text-15 {
    font-size: 15px;
  }

  .bonus {
    padding-top: 1px;
  }
</style>