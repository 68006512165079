<template>
  <div class="max-w-2xl mx-auto mb-8 px-4 md:px-0">
    <div class="pt-16 text-dark-asset">
      <div id="colors" class="title text-xl font-black text-center text-30">COLORS</div>
      <div class="max-w-sm mx-auto font-normal text-center mt-4 text-18">Brand colors palette consists of primary and additional colors.</div>
      <!--    Primary-->
      <div class="flex flex-row py-4">
        <div class="font-black">Primary</div>
        <div class="flex-1 border-b border-asset line"></div>
      </div>
      <div class="flex flex-col md:flex-row justify-between">
        <div class="flex flex-row my-6 mx-auto">
          <div class="bg-primaryDark h-32 w-32 rounded-full"></div>
          <div class="flex flex-col justify-between py-4 pl-4">
            <div class="text-lg font-semibold">COARSE WOOL</div>
            <div class="flex flex-row">
              <div class="left-icon">#</div>
              <div class="text-xs">1b2027</div>
            </div>
            <div class="flex flex-row">
              <div class="left-icon">rgb</div>
              <div class="text-xs">(27,32,39)</div>
            </div>
            <a class="bg-gray hover:bg-primary text-white px-2 py-1 text-xs rounded-full copy-button" v-on:click="copy('#1b2027')">Copy HEX</a>
          </div>
        </div>
        <div class="flex flex-row my-6 mx-auto">
          <div class="bg-primaryAsset h-32 w-32 rounded-full"></div>
          <div class="flex flex-col justify-between py-4 pl-4">
            <div class="text-lg font-semibold">OLD GOLD</div>
            <div class="flex flex-row">
              <div class="left-icon">#</div>
              <div class="text-xs">d19a3b</div>
            </div>
            <div class="flex flex-row">
              <div class="left-icon">rgb</div>
              <div class="text-xs">(209,154,59)</div>
            </div>
            <a class="bg-gray hover:bg-primary text-white px-2 py-1 text-xs rounded-full copy-button" v-on:click="copy('#d19a3b')">Copy HEX</a>
          </div>
        </div>
      </div>
      <!--      Secondary-->
      <div class="flex flex-row py-4">
        <div class="font-black">Secondary</div>
        <div class="flex-1 border-b border-asset line"></div>
      </div>
      <div class="flex flex-col md:flex-row justify-between">
        <div class="flex flex-row md:flex-col my-6 mx-auto">
          <div class="bg-secondaryPrimary h-32 w-32 rounded-full"></div>
          <div class="flex flex-col justify-between py-4 pl-4">
            <div class="text-lg font-semibold">GOTHIC GOLD</div>
            <div class="flex flex-row">
              <div class="left-icon">#</div>
              <div class="text-xs">bd872c</div>
            </div>
            <div class="flex flex-row my-1">
              <div class="left-icon">rgb</div>
              <div class="text-xs">(189, 135, 44)</div>
            </div>
            <a class="bg-gray hover:bg-primary text-white px-2 py-1 text-xs rounded-full copy-button" v-on:click="copy('#bd872c')">Copy HEX</a>
          </div>
        </div>
        <div class="flex flex-row md:flex-col my-6 mx-auto">
          <div class="bg-slateGray h-32 w-32 rounded-full"></div>
          <div class="flex flex-col justify-between py-4 pl-4">
            <div class="text-lg font-semibold">SLATE GRAY</div>
            <div class="flex flex-row">
              <div class="left-icon">#</div>
              <div class="text-xs">768595</div>
            </div>
            <div class="flex flex-row my-1">
              <div class="left-icon">rgb</div>
              <div class="text-xs">(118, 133, 149)</div>
            </div>
            <a class="bg-gray hover:bg-primary text-white px-2 py-1 text-xs rounded-full copy-button" v-on:click="copy('#768595')">Copy HEX</a>
          </div>
        </div>
        <div class="flex flex-row md:flex-col my-6 mx-auto">
          <div class="bg-whiteLilac h-32 w-32 rounded-full"></div>
          <div class="flex flex-col justify-between py-4 pl-4">
            <div class="text-lg font-semibold">WHITE LILAC</div>
            <div class="flex flex-row">
              <div class="left-icon">#</div>
              <div class="text-xs">f3f6fb</div>
            </div>
            <div class="flex flex-row my-1">
              <div class="left-icon">rgb</div>
              <div class="text-xs">(243, 246, 251)</div>
            </div>
            <a class="bg-gray hover:bg-primary text-white px-2 py-1 text-xs rounded-full copy-button" v-on:click="copy('#f3f6fb')">Copy HEX</a>
          </div>
        </div>
      </div>
      <!--      Gradient-->
      <div class="flex flex-row py-4">
        <div class="font-black">Gradient</div>
        <div class="flex-1 border-b border-asset line"></div>
      </div>
      <div class="flex flex-col md:flex-row justify-between items-center px-8">
        <div class="flex flex-col py-4 pl-4">
          <div class="text-lg font-semibold">RIVER STYX</div>
          <div class="flex flex-row my-4">
            <div class="left-icon">#</div>
            <div class="text-xs">121820</div>
          </div>
          <div class="flex flex-row mb-4">
            <div class="left-icon">rgb</div>
            <div class="text-xs">(18, 24, 32)</div>
          </div>
          <a class="bg-gray hover:bg-primary text-white px-2 py-1 text-xs rounded-full copy-button" v-on:click="copy('#121820')">Copy HEX</a>
        </div>
        <div class="w-48 h-48 gradient rounded-full"></div>
        <div class="flex flex-col py-4 pl-4">
          <div class="text-lg font-semibold">BLUE CLOUD</div>
          <div class="flex flex-row my-4">
            <div class="left-icon">#</div>
            <div class="text-xs">627185</div>
          </div>
          <div class="flex flex-row mb-4">
            <div class="left-icon">rgb</div>
            <div class="text-xs">(98, 113, 133)</div>
          </div>
          <a class="bg-gray hover:bg-primary text-white px-2 py-1 text-xs rounded-full copy-button" v-on:click="copy('#627185')">Copy HEX</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Colors',
    methods: {
      copy: function (hex) {
        this.$copyText(hex).then(function (e) {
          // alert('Copied')
        }, function (e) {
          // alert('Can not copy')
        })
      }
    }
  }
</script>

<style scoped>
  .title {
    letter-spacing: 0.5rem;
  }

  .line {
    transform: translateY(-50%);
    margin-left: 20px;
  }

  .copy-button {
    width: fit-content;
  }

  .gradient {
    background-image: radial-gradient(circle at 9rem 3rem, #5F6B7C, #121820);
  }

  .text-30 {
    font-size: 30px;
  }

  .text-18 {
    font-size: 18px;
  }

  .left-icon {
    position: relative;
    width: 15px;
    font-size: 8px;
    text-align: center;
  }
</style>