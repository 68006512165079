<template>
  <div class="relative max-w-6xl mx-auto">
    <div class="absolute text-sm z-50 w-full flex flex-row justify-between items-center h-24 px-2">
      <div class="flex flex-row items-center">
        <a href="#" class="mr-8 ml-4 md:ml-0">
          <img src="../assets/images1/icons/YINLogo.svg" alt="" class="h-12" />
        </a>
        <!-- <a class="mr-6 hidden md:block" href="#">{{ $t('home') }}</a>
        <a class="mr-6 hidden md:block" href="#">{{ $t('about') }}</a>
        <a class="mr-6 hidden md:block" href="#">{{ $t('contacts') }}</a> -->
      </div>
      <div class="flex-row justify-center items-center hidden md:flex text-secondary">
        <a href="#mechanism" class="menu uppercase" :class="path==='#mechanism' ? 'active' : ''">{{ $t('mechanism') }}</a>
        <a href="#tokenomics" class="menu uppercase ml-4" :class="path==='#tokenomics' ? 'active' : ''">{{ $t('tokenomics') }}</a>
        <a href="#grandMaster" class="menu uppercase ml-4" :class="path==='#grandMaster' ? 'active' : ''">{{ $t('grandMaster') }}</a>
        <a href="https://app.yin.finance/" target="_blank" class="bg-primary hover:bg-primary-h ml-12 px-8 py-3 rounded-full hidden md:block primary-button" style='color:#fff;'>App</a>
      </div>
    </div>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'

  export default {
    data() {
      return {
        isEnglish: Cookies.get('locale') === 'en',
        isChinese: Cookies.get('locale') === 'zh',
      }
    },
    computed: {
      path() {
        return this.$route.hash
      },
    },
    methods: {
      changeLanguage(language) {
        this.$i18n.locale = language
        Cookies.set('locale', language)
        if (language === 'zh') {
          this.isChinese = true
          this.isEnglish = false
        } else {
          this.isChinese = false
          this.isEnglish = true
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .selected {
    opacity: 1;
  }

  .menu {
    border-bottom: 2px solid transparent;

    &.active, &:hover {
      border-bottom: 2px solid #D19A3B;
      color: black;
    }
  }
</style>
