<template>
  <div class="bg-white text-dark md:pb-16">
    <div class="max-w-6xl m-auto text-center flex flex-col justify-center items-center">
      <div class="text-2xl md:text-5xl font-medium">
        {{$t("investors")}}
      </div>
      <div class="flex flex-col md:flex-row mt-8 md:mt-16">
        <div class="md:mr-8 w-64 flex-1">
          <img src="../assets/images/people.png">
          <div class="relative label">Addison</div>
        </div>
        <div class="md:mr-8 w-64 flex-1">
          <img src="../assets/images/people.png">
          <div class="relative label">Addison</div>
        </div>
        <div class="md:mr-8 w-64 flex-1">
          <img src="../assets/images/people.png">
          <div class="relative label">Addison</div>
        </div>
        <div class="w-64 flex-1">
          <img src="../assets/images/people.png">
          <div class="relative label">Addison</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Investors',
  }
</script>

<style scoped>
  .label {
    top: -4rem;
    height: 4rem;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    line-height: 4rem;
    font-weight: 600;
    font-size: 1.5rem;
  }
</style>