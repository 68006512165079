<template>
  <div class="text-light overflow-hidden">
    <!--    section one-->
    <div class="bg-dark pb-16 md:pb-0 md:mt-reverted-16 pt-8 md:pt-20">
      <div class="px-4 md:px-0 max-w-6xl m-auto flex flex-col md:flex-row items-center justify-between">
        <div class="pt-16 md:pt-0 flex flex-row">
          <img src="../assets/images1/icons/YINIcon.svg">
          <div class="flex flex-col text-sm ml-4">
            <a href="https://github.com/YinFinance" class="mt-3 opacity-50 font-light">@2022</a>
            <div>YIN Finance</div>
          </div>
        </div>
        <div class="mt-6 flex">
          <!-- Begin Mailchimp Signup Form -->
          <div id="mc_embed_signup" style='clear:left; font:14px Helvetica,Arial,sans-serif;' class="flex">
            <form action="https://finance.us6.list-manage.com/subscribe/post?u=4edcdaafb1f51421f8dcf5de7&amp;id=759d0f6fdb" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll" class="relative text-center">
                <label for="mce-EMAIL"><b>{{ $t('getInTouch') }}</b>&nbsp{{ $t('stayUpdated') }}</label>
                <input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Enter your email" required style='width: 300px;'>
                <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_4edcdaafb1f51421f8dcf5de7_759d0f6fdb" tabindex="-1" value=""></div>
                <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button bg-primary"></div>
              </div>
            </form>
          </div>
        </div>
        <div class="border-l border-r px-12 border-dark hidden lg:block">
          <div class="font-bold">Contact Us</div>
          <a href="mailto:market@yin.finance" class="button1 doc-button hover:bg-primary inline-block secondary-button-right">
            market@yin.finance
          </a>
        </div>
        <div>
          <div class="flex flex-row ">
            <a class="opacity-75 hover:opacity-100" href='https://t.me/YinFinance' target="_blank">
              <img class="h-6" src="../assets/images1/socialMedia/telegrtam.svg">
            </a>
            <a class="opacity-75 hover:opacity-100 ml-8" href='https://discord.gg/EWjXDEPuAU' target="_blank">
              <img class="h-6" src="../assets/images1/socialMedia/discord.png">
            </a>
            <a class="opacity-75 hover:opacity-100 ml-8" href='https://medium.com/@yinfinance' target="_blank">
              <img class="h-6" src="../assets/images1/socialMedia/medium.png">
            </a>
            <a class="opacity-75 hover:opacity-100 ml-8" href='https://twitter.com/YinFinance' target="_blank">
              <img class="h-6" src="../assets/images1/socialMedia/twitter.svg">
            </a>
          </div>
          <div class="mt-4 flex flex-row justify-between">
            <a href="https://docs.yin.finance/" class="opacity-75 hover:opacity-100 hover:underline">Doc</a>
            <a href="https://github.com/YinFinance/" class="opacity-75 hover:opacity-100 hover:underline">Github</a>
            <router-link class="opacity-75 hover:opacity-100 hover:underline" to="/asset">Brand assets</router-link>
          </div>
        </div>
      </div>
      <div>
      </div>
      <div></div>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'MyFooter',
  }
</script>

<style lang="scss" scoped>
  #mc_embed_signup {
    font-family: 'Red Hat Display', sans-serif !important;
  }

  .email {
    background: #0E1116 !important;;
    border: 1px solid #3C424B !important;
    border-radius: 999px !important;
    padding: 0.6rem 1.5rem !important;
    font-size: 0.8rem !important;
  }

  .email::placeholder {
    color: #76859599 !important;
  }

  .button {
    position: absolute !important;
    bottom: -8px !important;
    right: 2px !important;
    border-radius: 999px !important;
    background: #C99B4D !important;
  }

  .button1 {
    outline: none;
    padding: 0.5rem 0;
    width: 11rem;
    border-radius: 2rem;
    text-align: center;
    cursor: pointer;
  }

  .doc-button {
    border: 1px solid #C99B4D;
  }
</style>
