<template>
  <div class="flex flex-col items-center justify-start relative h-screen overflow-hidden">
    <img class="absolute banner-bg" src="../../assets/images1/banner/bg.png" alt="">
    <img class="absolute hero" src="../../assets/images1/banner/hero.png" alt="">

    <div class="z-10 flex flex-col justify-end items-center" style="height: 45%;">
      <div class="text-light text-5xl md:text-6xl font-bold">
        {{ $t('banner1') }}
      </div>
      <div class="text-5xl md:text-6xl font-bold">
        <span class="text-light">{{ $t('banner2') }}</span>
        <span class="text-primary">{{ $t('banner3') }}</span>
      </div>
      <div class="text-light text-xl">
        {{ $t('banner4') }}
      </div>
    </div>

  </div>
</template>

<script>
  export default {}
</script>

<style lang="scss" scoped>
  .banner-bg {
    max-width: none;
    height: 140vh;
    left: 50%;
    top: 0;
    transform: translateX(-70%) translateY(-25%);
  }

  .hero {
    bottom: 0vh;
    max-width: none;
    width: 300vw;
    left: 50%;
    transform: translateX(-55%);
  }
</style>
