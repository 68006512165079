<template>
  <div>
    <pc-banner class="hidden md:block"></pc-banner>
    <mobile-banner class="block md:hidden"></mobile-banner>
  </div>
</template>

<script>
  import MobileBanner from './MobileTokenomics'
  import PcBanner from './PcTokenomics'

  export default {
    components: {
      MobileBanner,
      PcBanner,
    },
  }
</script>

<style lang="scss" scoped>
</style>
