<template>
  <div>
    <MobileRoadMap class="block lg:hidden"/>
    <PcMobile class="hidden lg:block"/>
  </div>
</template>

<script>
import MobileRoadMap from './MobileRoadMap'
import PcMobile from './PcRoadMap'
export default {
  name: "RoadMap",
  components: { PcMobile, MobileRoadMap },
}
</script>

<style scoped>

</style>