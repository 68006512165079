<template>
  <div class="banner">
    <div class="flex flex-col items-center max-w-2xl mx-auto">
      <div class="text-72 font-black pt-40 pb-12">
        Brand Assets
      </div>
      <div id="logo" class="title text-30 mb-6 font-black">LOGO</div>
      <div class="text-center mb-12 text-18 font-normal">The Yin logo displayed in the official colors. Do not place the logo on top of other objects and leave a reasonable amount of margin around the outside so the logo is clearly visible.</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Banner',
  }
</script>

<style scoped>
  .text-72 {
    font-size: 72px;
  }

  .text-30 {
    font-size: 30px;
  }

  @media screen and (max-width: 28rem) {
    .text-72 {
      font-size: 2.25rem;
    }

    .text-30 {
      font-size: 1.25rem;
    }
  }

  .text-18 {
    font-size: 18px;
  }
  .banner {
    background: url("../../assets/images/asset/banner-bg.jpg");
    background-size: cover;
  }
  .title {
    letter-spacing: 0.5rem;
  }
</style>