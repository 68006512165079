export default {
  about: '关于',
  about1: 'About',
  automaticallyReInvested: '自动再投资',
  automaticallyReInvestedDescription: '在Uniswap v3中，用户需要手动收取费用进行再投资，YIN 协议中自带复投功能，让你避免错过复利增长。',
  banner1: '去中心化',
  banner2: '金融中的',
  banner3: '流动性解决方案',
  banner4: '流动性解决方案',
  coinGecko: 'CoinGecko',
  coinMarketCap: 'Coin Market Cap',
  contactUs: 'Contact Us',
  contacts: '联系我们',
  disclaimer: 'YIN Protocol Disclaimer',
  disclaimerWebsite: 'Disclaimer Website',
  dos: 'Tokenomics Dos',
  email: 'contact@yin.finance',
  getCoverage: 'Get Coverage',
  goToVaults: '前往Vaults',
  grandMaster: 'Grand Master',
  grandMasterDescription: 'Grand Master is a protocol for leveraging your position in liquidity. Asset lenders can earn high interest on their Asset, and lenders can get even higher transaction fees and farming APY from taking on leveraged positions on yield farming.',
  home: '主页',
  home1: 'Home',
  "Coming soon": "即将上线",
  investors: 'Backed by Global Investors',
  launchApp: '启动App',
  liquidityManagement: '主动流动性管理',
  liquidityManagementDescription: '用户可以选择订阅各种可通过其个人Smart Vault获得的CHI。',
  mechanism: 'YIN的机制',
  more: "More",
  yinProducts: "YIN products",
  coming: 'coming...',
  pages: 'PAGES',
  position: 'Untere Rainstrasse 64 6340 Baar Switzerland',
  poweredBy: 'Powered by Nexus Mutual',
  privacy: 'Privacy Policy',
  subscribe: 'Subscribe to our newsletter for updates',
  tokenomics: 'YIN的代币经济学',
  tokenomicsDescription: '',
  uniswap: 'Uniswap',
  yin: 'YIN',
  yieldMining: '多方收益挖掘',
  yieldMiningDescription: 'YIN 拥有顶尖的智能合约审计团队，在对接第三方挖矿协议中，能让你的资产利用率最大化，为你赢取多方受益。',
}