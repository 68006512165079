<template>
  <div class="bg-asset-light text-dark pt-16 px-4 md:px-0">
    <div class="max-w-5xl mx-auto">
      <div id="fonts" class="title text-xl font-black text-center text-30">FONTS</div>
      <div class="text-center mt-4 font-normal text-18">Typography we use one type of font. You can download it from the</div>
      <div class="flex justify-center">
        <a class="text-primary underline" href="https://fonts.google.com/specimen/Red+Hat+Display">Google font Red Hat Display</a>
      </div>
      <div class="flex flex-col md:flex-row mt-8 md:mt-16 justify-between pb-16">
        <div class="flex flex-col md:w-3/5 justify-between md:mr-16">
          <div class="font-black text-42">Red Hat Display</div>
          <div class="font-normal text-18 mt-4">Red Hat is a family of typefaces produced in 2 optical sizes, in a range of weights with italics. The fonts were originally commissioned by Paula Scher, Pentagram and designed by Jeremy Mickel, MCKL for the new Red Hat identity.</div>
        </div>
        <div class="flex-1 flex flex-col justify-between bg-white rounded-lg px-8 py-4 mt-4 md:mt-0">
          <div class="text-xl font-black">Red Hat Display Black</div>
          <div class="text-xl font-bold">Red Hat Display Bold</div>
          <div class="text-xl font-medium">Red Hat Display Medium</div>
          <div class="text-xl font-normal">Red Hat Display Regular</div>
        </div>
      </div>
      <div class="flex flex-col bg-white px-8 md:px-16 pt-6 pb-2 rounded-lg mb-4 text-18">
        <div class="flex flex-row font-black">
          <div>Slogan&nbsp;</div>
          <div class="text-secondary">Red Hat Display Black 72pt</div>
        </div>
        <div class="text-72 font-black">The Solution to Liquidity.</div>
      </div>
      <div class="flex flex-col bg-white px-8 md:px-16 pt-6 pb-4 rounded-lg mb-4">
        <div class="flex flex-row font-black text-18">
          <div>H1&nbsp;</div>
          <div class="text-secondary">Red Hat Display Black 42pt & Red Hat Display Regular 42pt</div>
        </div>
        <div class="flex flex-row text-42">
          <div class="text-42 font-black">Mechanism&nbsp;</div>
          <div class="text-42 font-normal">of YIN</div>
        </div>
      </div>
      <div class="flex flex-col bg-white px-8 md:px-16 pt-6 pb-4 rounded-lg">
        <div class="flex flex-row font-black text-18">
          <div>H2&nbsp;</div>
          <div class="text-secondary">Red Hat Display Black 24pt</div>
        </div>
        <div class="flex flex-row">
          <div class="text-xl font-black text-24">Active Liquidity Management</div>
        </div>
      </div>
      <div class="flex flex-col items-center px-8 md:px-20">
<!--        <div class="flex flex-row font-black py-6 md:py-12">-->
<!--          <div>Body&nbsp;</div>-->
<!--          <div class="text-secondary">Red Hat Display Regular 18pt</div>-->
<!--        </div>-->
<!--        <div class="font-bold text-center text-18">-->
<!--          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc malesuada, elit vel interdum tempor, nunc elit iaculis diam, nec porta arcu mauris laoreet odio.-->
<!--        </div>-->
<!--        <div class="font-normal pt-6 md:pt-12 pb-4 text-center">-->
<!--          Aenean ut ipsum elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In mattis velit ac orci lacinia eleifend. Aenean blandit non lectus vitae iaculis. Duis at tincidunt tortor, ac fringilla tellus. Ut nec magna at leo rhoncus eleifend.-->
<!--        </div>-->
        <a class="bg-primary hover:bg-primary-h px-6 md:px-8 py-3 rounded-full text-white primary-button flex flex-row text-sm items-center download-font" href="/download/Red_Hat_Display.zip" download>
          <img class="mr-3" src="../../assets/images/asset/download_icon_white.svg">
          Download Font
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Fonts',
  }
</script>

<style scoped>
  .title {
    letter-spacing: 0.5rem;
  }

  .download-font {
    transform: translateY(50%);
  }

  .text-72 {
    font-size: 72px;
  }

  .text-30 {
    font-size: 30px;
  }

  .text-18 {
    font-size: 18px;
  }

  .text-42 {
    font-size: 42px;
  }

  .text-24 {
    font-size: 24px;
  }

  @media screen and (max-width: 28rem) {
    .text-72 {
      font-size: 2.25rem;
    }

    .text-42 {
      font-size: 1.8rem;
    }

    .text-30 {
      font-size: 1.5rem;
    }

    .text-24 {
      font-size: 1.25rem;
    }
  }
</style>