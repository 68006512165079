<template>
  <div id="tokenomics" class="tokenomics">
    <div class="flex flex-col md:flex-row py-8 md:py-0 justify-between items-center h-full md:px-24">
      <div class="flex flex-row justify-center items-center">
        <div class="max-w-xl flex flex-col items-center md:items-start mr-6 pr-6 md:border-r-2">
          <div class="font-bold text-3xl md:text-5xl">YIN</div>
          <div class="text-xl md:text-4xl">{{ $t('tokenomics') }}</div>
        </div>
        <div class="text-light font-thin max-w-sm py-4 md:py-0 pl-4" style="color: #B0B5BC">{{ $t('tokenomicsDescription') }}</div>
      </div>
      <img src="../../assets/images1/tokenomics/TokenLiquid.png" class="h-80 mt-reverted-12 hidden md:block">
      <div class="flex flex-col items-center justify-center relative z-10 hidden lg:flex">
        <a href="https://docs.yin.finance/" target="_blank" class="button doc-button secondary-button hover:bg-primary">{{ $t('doc') }}</a>
        <a class="bg-primary hover:bg-primary-h mt-4 button primary-button">{{ $t('Coming Soon') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Tokenomics',
  }
</script>

<style scoped lang="scss">
  .tokenomics {
    background-image: url(../../assets/images1/tokenomics/TokenomicsBG.png);
    background-size: cover;
    transform: translateY(-50%);
  }

  @media screen and (min-width: 1440px) {
    .tokenomics {
      border-radius: 20px 0 0 20px;
      width: calc(100% - 16rem);
      transform: translateY(-50%) translateX(16rem);
    }
  }

  .button {
    outline: none;
    padding: 0.7rem 0;
    width: 11rem;
    border-radius: 2rem;
    text-align: center;
    cursor: pointer;
  }

  .doc-button {
    border: 1px solid #C99B4D;
  }
</style>