<template>
  <div id="tokenomics" class="tokenomics mb-20">
    <div class="flex flex-col py-8 justify-between items-center h-full text-center">
      <div class="flex flex-col items-center">
        <div class="font-bold text-3xl">YIN</div>
        <div class="text-xl">{{ $t('tokenomics') }}</div>
      </div>
      <div class="text-light font-thin max-w-sm py-4 pl-4" style="color: #B0B5BC">{{ $t('tokenomicsDescription') }}</div>
      <div class="flex flex-col items-center justify-center relative z-10">
        <a href="https://docs.yin.finance/" target="_blank" class="button doc-button secondary-button hover:bg-primary">{{ $t('doc') }}</a>
        <a class="bg-primary hover:bg-primary-h mt-4 button primary-button">{{ $t('Coming Soon') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Tokenomics',
  }
</script>

<style scoped lang="scss">
  .tokenomics {
    background-image: url(../../assets/images1/tokenomics/TokenomicsBG.png);
    background-size: cover;
    width: 100% !important;
    transform: translateY(0);
  }

  .button {
    outline: none;
    padding: 0.7rem 0;
    width: 11rem;
    border-radius: 2rem;
    text-align: center;
    cursor: pointer;
  }

  .doc-button {
    border: 1px solid #C99B4D;
  }
</style>