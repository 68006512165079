<template>
  <div id="mechanism" class="md:mt-0 bg-white text-dark">
      <div class="text-2xl md:text-4xl font-bold text-center">
        {{ $t('mechanism') }}
      </div>
      <div class="text-2xl text-center">{{$t('ofYin')}}</div>
      <div>
        <div class="flex-1 flex flex-col md:flex-row items-center justify-between mt-12 max-w-5xl mx-auto">
          <div class="md:w-5/12 md:border-l-2 border-primary pl-4">
            <div class="mb-6 text-2xl font-bold">{{ $t('liquidityManagement') }}</div>
            <div>{{ $t('liquidityManagementDescription') }}</div>
          </div>
          <div class="flex-1">
            <img src="../assets/images1/mechanism/ActiveLiquidityManagement.png" class="md:h-100 float-right">
          </div>
        </div>
        <div class="bg-light md:mt-reverted-24">
          <div class="flex-1 flex flex-col-reverse md:flex-row items-center max-w-5xl mx-auto">
            <div class="flex-1">
              <img src="../assets/images1/mechanism/AutomaticallyReinvested.png" class="h-64 md:h-100 my-24">
            </div>
            <div class="md:w-5/12 md:border-l-2 border-primary mt-4 md: mt-0 pl-4">
              <div class="mb-6 text-2xl font-bold ">{{ $t('automaticallyReInvested') }}</div>
              <div class="font-thin">{{ $t('automaticallyReInvestedDescription') }}</div>
            </div>
          </div>
        </div>

        <div class="flex-1 flex flex-col md:flex-row items-center max-w-5xl mx-auto">
          <div class="md:w-5/12 md:border-l-2 border-primary pl-4 mt-4 md: mt-0 ">
            <div class="mb-6 text-2xl font-bold">{{ $t('yieldMining') }}</div>
            <div class="font-thin">{{ $t('yieldMiningDescription') }}</div>
          </div>
          <div class="flex-1">
            <img src="../assets/images1/mechanism/MultiPartyYieldMining.png" class="md:h-92 float-right mt-12 md:mb-32">
          </div>
        </div>
      </div>
  </div>
</template>

<script>
  export default {
    name: 'Mechanism',
  }
</script>

<style lang="css" scoped>
  .banner {
    width: 100%;
    height: calc(100vh - 5rem);
    background-image: url("../assets/images/banner.png");
    background-size: cover;
  }

  #button1 {
    background: linear-gradient(90deg, #758BF7, #6CE9FC);
  }

  #button2 {
    background: #0F121E;
  }

</style>
