<template>
  <div class="max-w-4xl mx-auto text-black">
    <div class="font-bold text-4xl mb-32 text-center">Roadmap</div>
    <div class="flex flex-col mb-32">
      <div class="flex flex-row">
        <div class="pr-8 flex-1 mt-reverted-16">
          <div class="h-48 mb-4">
          </div>
          <div>
            <div class="h-38">
              <div class="text-right text-2xl font-semibold">Dec. 2021</div>
              <div class="text-right">Connect to Perpetual Protocol and deploy on Arbitrum network.</div>
            </div>
          </div>
          <div class="h-38">
          </div>
          <div class="h-38">
            <div class="text-right text-2xl font-semibold">Q2 2022</div>
            <div class="mt-2 text-right">Prepare open platform to provide API and Whitelist services for third-party strategy providers.</div>
          </div>
        </div>
        <div class="flex flex-col items-center">
          <img class="w-6 h-6" src="../../assets/images1/introduction/Kim.png" />
          <div class="h-32 border-l-2 border-r-2 border-roadmap"></div>
          <img class="w-6 h-6" src="../../assets/images1/introduction/Kim.png" />
          <div class="h-32 border-l-2 border-r-2 border-roadmap"></div>
          <img class="w-6 h-6" src="../../assets/images1/introduction/Kim.png" />
          <div class="h-32 border-l-2 border-r-2 border-roadmap"></div>
          <img class="w-6 h-6" src="../../assets/images1/introduction/Kim.png" />
          <div class="h-32 border-l-2 border-r-2 border-roadmap"></div>
          <img class="w-6 h-6" src="../../assets/images1/introduction/Kim.png" />
          <div class="h-32 dotted"></div>
        </div>
        <div class="pl-8 flex-1 border-roadmap mt-reverted-16">
          <div class="mt-8 pt-6 h-38">
            <div class="text-2xl font-semibold">Nov. 2021</div>
            <div>Complete audit, TGE, and mainnet online.</div>
          </div>
          <div class="h-24">
          </div>
          <div class="mt-4">
            <div class="flex flex-row items-center">
              <div>🛠</div>
              <div class="text-secondary">&nbsp;Current Phase</div>
            </div>
            <div class="bg-roadmapCard text-white left-full px-8 pt-6 mt-6 h-38">
              <div class="text-2xl font-semibold">Q1 2022</div>
              <div class="mt-2">Multi-chain deployment (BSC/Polygon).</div>
            </div>
          </div>
          <div class="h-38">
          </div>
          <div class="mt-4 h-38">
            <div class="text-2xl font-semibold">Q3 2022</div>
            <div class="mt-2">Phasing into an open platform, starts to collaborate with large financial instituitions and offers more comprehensive structural financial products.</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "PcMobile",
}
</script>

<style scoped>
  .left-full {
    border-radius: 5px 80px 80px 80px; /* top left, top right, bottom right, bottom left */
  }

  .right-full {
    border-radius: 80px 5px 80px 80px; /* top left, top right, bottom right, bottom left */
  }

  .h-38 {
    height: 9.5rem;
  }

  .dotted {
    width: 4px;
    background-image: linear-gradient(#202010 50%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 4px 16px;
    background-repeat: repeat-y;
  }
</style>
