<template>
  <div class="home m-auto">
    <div class='bg-black py-4 text-center px-8' style="letter-spacing: 1px" v-if="!hideNews">
      <div class="relative mx-auto" style="max-width: 80rem">
        <div class='hidden md:flex md:flex-row text-center justify-center items-center py-4'>
          <img src="../assets/images1/icons/YINIcon.svg" alt="" class="h-6 mr-2" />
          <div class="font-bold pt-1">$YIN is live on</div>
          <a class="ml-8 w-32 hover:opacity-75" href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x794baab6b878467f93ef17e2f2851ce04e3e34c8" target="_blank">
            <img src="../assets/images1/ido/uniswap.png" alt="" class='mx-auto'>
          </a>
          <a class="ml-8 w-24 hover:opacity-75" href="https://app.dodoex.io/exchange/BUSD-YIN?network=bsc-mainnet" target="_blank">
            <img src="../assets/images1/ido/DODO.svg" alt="" class='mx-auto'>
          </a>
          <a class="ml-8 w-24 pt-2 hover:opacity-75" href="https://www.gate.io/trade/YIN_USDT" target="_blank">
            <img src="../assets/images1/ido/Gate.svg" alt="" class='mx-auto'>
          </a>
          <a class="ml-8 w-24 hover:opacity-75" href="https://www.mexc.com/exchange/YIN_USDT" target="_blank">
            <img src="../assets/images1/ido/MEXC.svg" alt="" class='mx-auto'>
          </a>
        </div>

        <div class='flex md:hidden flex-row md:flex-row text-center justify-center items-center pt-4'>
          <img src="../assets/images1/icons/YINIcon.svg" alt="" class="h-6 mr-2" />
          <div class="font-bold pt-1">$YIN is live on</div>
        </div>
        <div class='flex md:hidden flex-row md:flex-row text-center justify-between items-center py-4'>
          <a class="mx-2 hover:opacity-75" href="https://app.dodoex.io/exchange/BUSD-YIN?network=bsc-mainnet" target="_blank">
            <img src="../assets/images1/ido/DODO.svg" alt="" class='mx-auto'>
          </a>
          <a class="mx-2 pt-2 hover:opacity-75" href="https://www.gate.io/trade/YIN_USDT" target="_blank">
            <img src="../assets/images1/ido/Gate.svg" alt="" class='mx-auto'>
          </a>
          <a class="mx-2 hover:opacity-75" href="https://www.mexc.com/exchange/YIN_USDT" target="_blank">
            <img src="../assets/images1/ido/MEXC.svg" alt="" class='mx-auto'>
          </a>
        </div>
        <div class="text-sm mb-4"><span style="color:#758292;">OFFICIAL YIN FINANCE TOKEN ($YIN) CONTRACT ADDRESS:</span> <span class="break-all">0x794Baab6b878467F93EF17e2f2851ce04E3E34C8 (ERC20/BEP20)</span></div>
        <img src="../assets/images1/close.svg" class="absolute right-0 top-0 cursor-pointer mt-3 -mr-4 lg:m-0 hover:opacity-75" @click="onClickHideNews" />
      </div>
    </div>
    <my-header></my-header>
    <banner></banner>
    <mechanism></mechanism>
    <introduction></introduction>
    <partners></partners>
    <tokenomics></tokenomics>
    <road-map></road-map>
    <product></product>
    <!--    <investors></investors>-->
    <my-footer></my-footer>
  </div>
</template>

<script>
  import MyHeader from '../components/MyHeader'
  import Banner from '../components/Banner/index'
  import MyFooter from '../components/MyFooter'
  import Mechanism from '../components/Mechanism'
  import Product from '../components/GrandMaster'
  import Tokenomics from '../components/Tokenmics'
  import Investors from '../components/Investors'
  import Introduction from '../components/Introduction'
  import Partners from '../components/Partners'
  import RoadMap from '../components/RoadMap/index'

  const hideFlag = 'hide_trade'

  export default {
    components: {
      MyHeader,
      Banner,
      MyFooter,
      Mechanism,
      Product,
      Tokenomics,
      Investors,
      Introduction,
      Partners,
      RoadMap,
    },
    data() {
      return {
        hideNews: !!localStorage.getItem(hideFlag),
      }
    },
    methods: {
      onClickHideNews() {
        this.hideNews = true
        localStorage.setItem(hideFlag, 'true')
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
