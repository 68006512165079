import { render, staticRenderFns } from "./PcTokenomics.vue?vue&type=template&id=5aeb42cf&scoped=true&"
import script from "./PcTokenomics.vue?vue&type=script&lang=js&"
export * from "./PcTokenomics.vue?vue&type=script&lang=js&"
import style0 from "./PcTokenomics.vue?vue&type=style&index=0&id=5aeb42cf&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aeb42cf",
  null
  
)

export default component.exports