<template>
  <div class="flex flex-col items-center justify-center relative overflow-hidden" style="height: 50vw; top: 0">
    <img class="absolute right-0 w-3/4" src="../../assets/images1/texture.png" alt="">
    <div class="absolute w-screen left-0 top-0 right-0 bottom-0 flex flex-row justify-center items-center" id="scene">
      <img class="banner-bg" src="../../assets/images1/banner/bg.png" alt="" data-depth="0.0">
      <img class="hero" src="../../assets/images1/banner/hero.png" alt="" data-depth="0.2">
    </div>

    <div class="relative max-w-6xl mx-auto pl-16 md:pl-12 lg:pl-2 z-20" style="margin-top: 25vw;transform: translateY(-50%)">
      <div class="text-light text-4xl md:text-5xl lg:text-6xl font-bold">
        {{ $t('banner1') }}
      </div>
      <div class="text-4xl md:text-5xl lg:text-6xl font-bold">
        <span class="text-light">{{ $t('banner2') }}</span>
        <span class="text-primary">{{ $t('banner3') }}</span>
      </div>
      <div class="text-light text-xl">
        {{ $t('banner4') }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    mounted() {
      var scene = document.getElementById('scene')
      var parallaxInstance = new Parallax(scene)
    },
  }
</script>

<style lang="scss" scoped>
  #scene {
  }

  .banner-bg {
    max-width: none;
    height: 120%;
    margin-top: -10%;
    margin-right: 45%;
    transform: translateY(-20px);
    animation: automove 1.4s ease-in-out infinite alternate;
  }

  .hero {
    max-width: none;
    height: 80%;
    margin-top: 7%;
    margin-left: -10%;
  }

  @keyframes automove {
    0% {
      transform: translateY(0)
    }

    to {
      transform: translateY(-10px)
    }
  }
</style>
