<template>
  <div class="text-dark bg-light pt-8 md:pt-16">
    <div class="font-bold text-4xl text-center">
      Partners
    </div>
    <div class="max-w-6xl mx-auto flex flex-wrap flex-row py-8 md:mt-24 md:py-0 items-center md:pb-64">
      <a v-for="i in items" :href="i.href" target="_blank" class="w-1/2 md:w-1/5 mb-12 text-center flex justify-center items-center">
        <img class="h-16 img" :src="i.imgUrl">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Partners',
  data() {
    return {
      items: [
        {
          imgUrl: '/partners/1_FENBUSHI.svg',
          href: 'https://www.fenbushicapital.vc/',
        },
        {
          imgUrl: '/partners/2_Chainlink.svg',
          href: 'https://chain.link/',
        },
        {
          imgUrl: '/partners/3_Polygon.svg',
          href: 'https://polygon.technology/',
        },
        {
          imgUrl: '/partners/4_EVG.svg',
          href: 'https://www.evg.co/',
        },
        {
          imgUrl: '/partners/19_SevenX.svg',
          href: 'http://www.7xvc.com/',
        },
        {
          imgUrl: '/partners/6_Iconium.png',
          href: 'https://www.iconium.it/',
        },
        {
          imgUrl: '/partners/7_Parsiq.svg',
          href: 'https://www.parsiq.net/',
        },
        {
          imgUrl: '/partners/8_Mathwallet.svg',
          href: 'https://mathwallet.org/',
        },
        {
          imgUrl: '/partners/9_1Sol.svg',
          href: 'https://1sol.io/',
        },
        {
          imgUrl: '/partners/14_Dodo.svg',
          href: 'https://dodoex.io/',
        },
        {
          imgUrl: '/partners/10_Pyth.svg',
          href: 'https://pyth.network/',
        },
        {
          imgUrl: '/partners/11_AXIA8.svg',
          href: 'https://www.axia8.com/',
        },
        {
          imgUrl: '/partners/12_KRYPITAL.svg',
          href: 'https://krypital.com/',
        },
        {
          imgUrl: '/partners/13_WePiggy.png',
          href: 'https://wepiggy.com/',
        },
        {
          imgUrl: '/partners/15_multichain.png',
          href: 'https://multichain.org/',
        },
        {
          imgUrl: '/partners/16_SOLV.svg',
          href: 'https://solv.finance/home',
        },
        {
          imgUrl: '/partners/17_RED BUILDING CAPITAL.svg',
          href: 'https://www.rbcap.io/',
        },
        {
          imgUrl: '/partners/18_DAO Maker.svg',
          href: 'https://daomaker.com/',
        },
        {
          imgUrl: '/partners/5_Dorahacks.png',
          href: 'https://dorahacks.com/',
        },
        {
          imgUrl: '/partners/20_DeSyn.svg',
          href: 'https://www.desyn.io/',
        },
        {
          imgUrl: '/partners/21_gate.io.svg',
          href: 'https://www.gate.io/',
        },
        {
          imgUrl: '/partners/22_Mr.Block.png',
          href: 'https://www.youtube.com/channel/UCN2hSM8fBcvZBa8OOKc24eg',
        },
        {
          imgUrl: '/partners/23_shima.svg',
          href: 'https://shima.capital/',
        },
        {
          imgUrl: '/partners/24_synfuture.svg',
          href: 'https://www.synfutures.com/',
        },
        {
          imgUrl: '/partners/25_mexc.svg',
          href: 'https://www.mexc.com/',
        },
        {
          imgUrl: '/partners/26_das.svg',
          href: 'https://da.systems/',
        },
        {
          imgUrl: '/partners/27_imtoken.svg',
          href: 'https://token.im/',
        },
        {
          imgUrl: '/partners/28_izumi.svg',
          href: 'https://izumi.finance/',
        },
        {
          imgUrl: '/partners/29_tidal.svg',
          href: 'https://tidal.finance/',
        },
        {
          imgUrl: '/partners/30_immunefi.svg',
          href: 'https://immunefi.com/',
        },
        {
          imgUrl: '/partners/31_gelato.svg',
          href: 'https://www.gelato.network/',
        }],
    }
  },
}
</script>

<style lang="scss" scoped>
  .yin {
    transform: translateY(50%);
  }

  .philosophy {
    letter-spacing: 0.1rem;
  }

  .sub-title {
    margin-bottom: 1rem;
  }

  .img {
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
</style>
