<template>
  <div class="text-dark">
    <div class="font-bold text-4xl text-center">YIN & YANG & CHI</div>
    <div class="text-xs philosophy font-semibold text-center my-4">PHILOSOPHY</div>
    <div class="flex flex-row mx-auto justify-center">
      <img class="h-16 w-16" src="../assets/images1/introduction/YIN.png">
      <img class="h-16 w-16 mx-8" src="../assets/images1/introduction/YANG.png">
      <img class="h-16 w-16" src="../assets/images1/introduction/CHI.png">
    </div>
    <!--    Yin-->
    <div class="max-w-6xl bg-dark flex md:flex-row mx-auto rounded-lg items-center yin py-8 md:py-0">
      <img class="h-92 my-24 ml-40 hidden md:block" src="../assets/images1/introduction/YIN.png">
      <div class="flex flex-col mx-6 md:ml-12 md:mr-20">
        <div class="flex flex-col md:flex-row uppercase items-center">
          <div class="font-bold text-white text-5xl">YIN</div>
          <div class="text-secondary border-b-2 border-secondary md:ml-4 sub-title">
            <div>YIN is our</div>
            <div>native platform token</div>
          </div>
        </div>
        <div class="mt-4 md:mt-12 text-light">
          <div class="flex flex-row mt-2">
            <img class="h-5 w-4 mr-2 pt-1" src="../assets/images1/introduction/Kim.png">
            <div>By staking in the YIN Stake Pool, users are able to share their profits from the platform fees.</div>
          </div>
          <div class="flex flex-row mt-2">
            <img class="h-5 w-4 mr-2 pt-1" src="../assets/images1/introduction/Kim.png">
            <div>YIN token holders can participate in the governance of the DAO thus managing existing projects directly and deciding on new supported assets.</div>
          </div>
          <div class="flex flex-row mt-2">
            <img class="h-5 w-4 mr-2 pt-1" src="../assets/images1/introduction/Kim.png">
            <div>Gains from liquidation in the leveraged liquidity pool will also be distributed.</div>
          </div>
          <div class="flex flex-row mt-2">
            <img class="h-5 w-4 mr-2 pt-1" src="../assets/images1/introduction/Kim.png">
            <div>Strategy providers need to spend YIN in order to create CHI.</div>
          </div>
        </div>
      </div>
    </div>
    <!--    Yang-->
    <div class="bg-light py-8 md:pt-48">
      <div class="max-w-6xl mx-auto flex flex-row items-center">
        <div class="flex flex-col mx-6">
          <div class="flex flex-col md:flex-row uppercase items-center">
            <div class="font-bold text-5xl">YANG</div>
            <div class="text-secondary border-b-2 border-secondary md:ml-4 sub-title">
              <div>YANG is user's personal</div>
              <div>NFT smart vault</div>
            </div>
          </div>
          <div class="mt-4 md:mt-12">
            All subsequent asset management actions and processes will be performed from their YANG smart vault, thus maintaining the custody of assets and effectively ensuring the fund’s security.
          </div>
        </div>
        <img class="h-92 mb-24 mt-48 ml-40 hidden md:block" src="../assets/images1/introduction/YANG.png">
      </div>
    </div>
    <!--    Chi-->
    <div class="flex flex-row max-w-6xl mx-auto items-center py-8">
      <img class="h-92 my-24 hidden md:block" src="../assets/images1/introduction/CHI.png">
      <div class="flex flex-col mx-6 md:ml-40">
        <div class="flex flex-col md:flex-row uppercase items-center">
          <div class="font-bold text-5xl">CHI</div>
          <div class="text-secondary border-b-2 border-secondary md:ml-4 sub-title">
            <div>a programable strategy NFT</div>
            <div>describing liquidity structure</div>
          </div>
        </div>
        <div class="mt-4 md:mt-12">
          CHI is the most important part of YIN Finance as every single strategy provider can mint CHI. And users can subscribe CHI to transform their assets into liquidity.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Introduction',
  }
</script>

<style lang="scss" scoped>
  .yin {
    margin-top: -222px;
    transform: translateY(50%);
  }

  @media screen and (max-width: 28rem) {
    .yin {
      transform: translateY(0);
    }
  }

  .philosophy {
    letter-spacing: 0.1rem;
  }

  .sub-title {
    line-height: 1rem;
    height: 2.4rem;
    font-weight: bold;
  }
</style>
