<template>
  <div class="home m-auto">
    <Header/>
    <Banner/>
    <Logo/>
    <Colors/>
    <Fonts/>
    <Images/>
    <Footer/>
  </div>
</template>

<script>
  import Header from '../components/Asset/Header'
  import Banner from '../components/Asset/Banner'
  import Logo from '../components/Asset/Logo'
  import Colors from '../components/Asset/Colors'
  import Fonts from '../components/Asset/Fonts'
  import Images from '../components/Asset/Images'
  import Footer from '../components/Asset/Footer'
  export default {
    name: 'Asset',
    components: { Footer, Images, Fonts, Colors, Logo, Banner, Header },
  }
</script>

<style scoped>

</style>