<template>
  <div class="flex flex-col text-black max-w-xl mb-12 mx-auto md:px-0">
    <div class="font-bold text-4xl mb-24 text-center">Roadmap</div>
    <div class="flex flex-row text-black max-w-xl mb-24 mx-auto px-6 md:px-0">
      <div class="flex flex-col items-center">
        <img class="w-6-important h-6" src="../../assets/images1/introduction/Kim.png" />
        <div class="h-36 border-l-2 border-r-2 border-roadmap"></div>
        <img class="w-6-important h-6" src="../../assets/images1/introduction/Kim.png" />
        <div class="h-32 border-l-2 border-r-2 border-roadmap"></div>
        <img class="w-6-important h-6" src="../../assets/images1/introduction/Kim.png" />
        <div class="h-32 border-l-2 border-r-2 border-roadmap"></div>
        <img class="w-6-important h-6" src="../../assets/images1/introduction/Kim.png" />
        <div class="h-32 border-l-2 border-r-2 border-roadmap"></div>
        <img class="w-6-important h-6" src="../../assets/images1/introduction/Kim.png" />
        <div class="h-32 dotted"></div>
      </div>
      <div class="ml-6">
        <div class="h-38">
          <div class="text-lg font-semibold">Nov. 2021</div>
          <div class="text-sm">Complete audit, TGE, and mainnet online.</div>
        </div>
        <div class="mt-4 h-16">
          <div class="text-lg font-semibold">Dec. 2021</div>
          <div class="text-sm">Connect to Perpetual Protocol and deploy on Arbitrum network.</div>
        </div>
        <div class="mt-10 h-38">
          <div class="flex flex-row items-center">
            <div>🛠</div>
            <div class="text-secondary">&nbsp;Current Phase</div>
          </div>
          <div class="mt-2 bg-roadmapCard text-white left-full px-4 pt-4 pb-6">
            <div class="text-lg font-semibold">Q1 2022</div>
            <div class="mt-2 text-sm">Multi-chain deployment (BSC/Polygon).</div>
          </div>
        </div>
        <div class="mt-12 h-38">
          <div class="text-lg font-semibold">Q2 2022</div>
          <div class="mt-2  text-sm">Prepare open platform to provide API and Whitelist services for third-party strategy providers.</div>
        </div>
        <div class="h-38">
          <div class="text-lg font-semibold">Q3 2022</div>
          <div class="mt-2 text-sm">Phasing into an open platform, starts to collaborate with large financial instituitions and offers more comprehensive structural financial products.</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "MobileRoadMap",
}
</script>

<style scoped>
  .dotted {
    width: 4px;
    background-image: linear-gradient(#202010 50%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 4px 16px;
    background-repeat: repeat-y;
  }

  .left-full {
    border-radius: 5px 80px 80px 80px; /* top left, top right, bottom right, bottom left */
  }

  .w-6-important {
    width: 1.5rem;
    max-width: 1.5rem;
  }

  .mt-14 {
    margin-top: 3.5rem;
  }

  .h-38 {
    height: 9.5rem;
  }

  .h-36 {
    height: 9rem;
  }
</style>
