<template>
  <div id="grandMaster" class="bg-white text-dark grandMaster">
    <div class="max-w-6xl mx-auto flex flex-row justify-center items-center md:px-2 relative overflow-hidden">
      <img class="absolute top-0 right-0 w-full md:w-1/2 z-0" src="../assets/images1/texture.png" alt="" style="top: -6rem">
      <img src="../assets/images1/product/WomenWarrior.png" class="w-1/2 hidden md:block relative z-10">
      <div class="md:ml-12 mt-4 md:mt-0 relative z-10 flex-1">
        <div class="text-2xl md:text-5xl font-medium text-center md:text-left">
          <b>{{ $t('grand') }}</b>&nbsp{{ $t('master') }}
        </div>
        <div class="font-thin mt-4 md:mt-12 md:max-w-xl description px-4 md:px-0">
          {{ $t('grandMasterDescription') }}
        </div>
        <div class="items-center flex justify-center md:justify-start mt-8 md:mt-12 mb-8 md:mb-0">
          <a class="bg-primary hover:bg-primary-h rounded-full text-white w-32 h-12 button primary-button">Coming soon</a>
        </div>
      </div>
    </div>
    <div class="flex justify-center items-center relative h-24 md:h-32 bg-moreyin max-w-6xl mx-auto -mb-16">
      <div class="text-white text-xl md:text-3xl">{{ $t('upcoming') }}&nbsp<b>{{ $t('yinProducts') }}</b></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Mechanism',
  }
</script>

<style lang="scss" scoped>
  .grandMaster {
    margin-top: -4rem;
  }

  .button {
    outline: none;
    padding: 0.7rem 0;
    width: 11rem;
    border-radius: 2rem;
    text-align: center;
    cursor: pointer;
  }

  .bg-moreyin {
    background-image: url("../assets/images1/product/ComingSoonBG.png");
    background-size: 100% 8rem;
    background-repeat: no-repeat;
  }

  @media screen and (max-width: 28rem) {
    .bg-moreyin {
      background-size: 100% 6rem;
    }
  }
</style>
