<template>
  <div>
    <div class="flex flex-col md:flex-row text-dark justify-center">
      <div class="mt-reverted-24">
        <img src="../../assets/images1/product/WomenWarrior.png" class="warrior">
      </div>
      <div class="flex flex-col justify-between items-center md:items-start py-6 md:py-20 md:ml-8">
        <div class="text-2xl font-black">Contact Us</div>
        <a class="bg-primary hover:bg-primary-h px-8 py-3 rounded-full text-white primary-button flex flex-row text-sm items-center fit-content">market@yin.finance</a>
        <div class="flex flex-row ">
          <a class="opacity-50 hover:opacity-100" href='https://t.me/YinFinance' target="_blank">
            <img class="h-6" src="../../assets/images1/socialMediaDark/telegrtam.svg">
          </a>
          <a class="opacity-50 hover:opacity-100 ml-8" href='https://discord.gg/EWjXDEPuAU' target="_blank">
            <img class="h-6" src="../../assets/images1/socialMediaDark/discord.png">
          </a>
          <a class="opacity-50 hover:opacity-100 ml-8" href='https://medium.com/@yinfinance' target="_blank">
            <img class="h-6" src="../../assets/images1/socialMediaDark/medium.png">
          </a>
          <a class="opacity-50 hover:opacity-100 ml-8" href='https://twitter.com/YinFinance' target="_blank">
            <img class="h-6" src="../../assets/images1/socialMediaDark/twitter.svg">
          </a>
        </div>
        <div class="flex flex-row text-slateGray">
          <div class="font-normal">© 2021&nbsp;</div>
          <div class="font-bold">YIN Finance</div>
        </div>
      </div>
    </div>
    <div id="backToTop" class="fixed cursor-pointer hidden md:block" v-on:click="backToTop">
      <div class="flex flex-col items-center">
        <div class="w-5 h-5 rounded-full bg-slateGray flex justify-center items-center">
          <img src="../../assets/images/asset/up_arrow.svg">
        </div>
        <div class="text-slateGray text-sm">Back to Top</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    methods: {
      backToTop: function() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    }
  }
</script>

<style lang="scss" scoped>
  .warrior {
    width: 32rem;
  }
  #backToTop {
    bottom: 7.825rem;
    right: 5rem;
  }
  .fit-content {
    width: fit-content;
  }
</style>
