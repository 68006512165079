import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faReddit, faTwitter, faTelegram, faDiscord, faGithub, faAndroid, faApple } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight, faBook, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueClipboard from 'vue-clipboard2'
import './assets/styles/tailwind.css'
import './assets/styles/main.scss'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'

library.add(
  faReddit, faTwitter, faTelegram, faDiscord, faGithub,
  faArrowRight, faBook, faQuoteLeft, faAndroid, faApple,
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueAnalytics, {
  id: '',
  router,
  debug: {
    enabled: false,
  },
})
Vue.use(VueClipboard)
Vue.config.devtools = true
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')

window.onscroll = () => {
  if (!window.matchMedia("(max-width: 448px)").matches  && (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)) {
    document.getElementById("backToTop").style.display = "block";
  } else {
    document.getElementById("backToTop").style.display = "none";
  }
}
