<template>
  <div class="bg-asset-light text-dark">
    <div class="max-w-4xl mx-auto">
      <!--      Horizontal-->
      <div class="flex flex-row py-4 px-4 md:px-0">
        <div class="font-black">Horizontal</div>
        <div class="flex-1 border-b border-asset line"></div>
      </div>
      <div class="flex flex-col md:flex-row md:justify-between">
        <div class="row mx-auto">
          <div class="bg-white py-42-px px-29 rounded-5">
            <img class="w-32 h-24" src="../../assets/images/logo-horizontal-black.svg">
          </div>
          <div class="flex flex-row justify-between my-3 px-2">
            <div class="flex-1 flex flex-row">
              <div class="text-dark">Logo&nbsp;</div>
              <div class="text-dark font-semibold">Dark</div>
            </div>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1 mr-4" href="/download/png/HorizontalDark.png" download>PNG</a>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1" href="/download/svg/HorizontalDark.svg" download>SVG</a>
          </div>
        </div>
        <div class="row mx-auto">
          <div class="bg-black py-42-px px-29 rounded-5">
            <img class="w-32 h-24" src="../../assets/images/logo-horizontal-white.svg">
          </div>
          <div class="flex flex-row justify-between my-3 px-2">
            <div class="flex-1 flex flex-row">
              <div class="text-dark">Logo&nbsp;</div>
              <div class="text-dark font-semibold">White</div>
            </div>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1 mr-4" href="/download/png/HorizontalWhite.png" download>PNG</a>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1" href="/download/svg/HorizontalWhite.svg" download>SVG</a>
          </div>
        </div>
      </div>
      <!--      Vertical-->
      <div class="flex flex-row py-4 px-4 md:px-0">
        <div class="font-black">Vertical</div>
        <div class="flex-1 border-b border-asset line"></div>
      </div>
      <div class="flex flex-col md:flex-row justify-between">
        <div class="row mx-auto">
          <div class="bg-white py-42-px px-29 rounded-5">
            <img class="w-32 h-24" src="../../assets/images/logo-vertical-black.svg">
          </div>
          <div class="flex flex-row justify-between my-3 px-2">
            <div class="flex-1 flex flex-row">
              <div class="text-dark">Logo&nbsp;</div>
              <div class="text-dark font-semibold">Dark</div>
            </div>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1 mr-4" href="/download/png/VerticalDark.png" download>PNG</a>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1" href="/download/svg/VerticalDark.svg" download>SVG</a>
          </div>
        </div>
        <div class="row mx-auto">
          <div class="bg-black py-42-px px-29 rounded-5">
            <img class="w-32 h-24" src="../../assets/images/logo-vertical-white.svg">
          </div>
          <div class="flex flex-row justify-between my-3 px-2">
            <div class="flex-1 flex flex-row">
              <div class="text-dark">Logo&nbsp;</div>
              <div class="text-dark font-semibold">White</div>
            </div>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1 mr-4" href="/download/png/VerticalWhite.png" download>PNG</a>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1" href="/download/svg/VerticalWhite.svg" download>SVG</a>
          </div>
        </div>
      </div>
      <!--      symbol & token-->
      <div class="flex flex-row py-4 px-4 md:px-0">
        <div class="font-black">Symbol & Token</div>
        <div class="flex-1 border-b border-asset line"></div>
      </div>
      <div class="flex flex-wrap md:flex-no-wrap md:flex-row justify-between">
        <div class="mx-auto">
          <div class="bg-white p-10 rounded-lg">
            <img class="h-28" src="../../assets/images/asset/SymbolDark.svg">
          </div>
          <div class="flex flex-row justify-between my-3 px-2">
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1 mr-4" href="/download/png/SymbolDark.png" download>PNG</a>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1" href="/download/svg/SymbolDark.svg" download>SVG</a>
          </div>
        </div>
        <div class="mx-auto">
          <div class="bg-black p-10 rounded-lg">
            <img class="h-28" src="../../assets/images/asset/SymbolWhite.svg">
          </div>
          <div class="flex flex-row justify-between my-3 px-2">
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1 mr-4" href="/download/png/SymbolWhite.png" download>PNG</a>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1" href="/download/svg/SymbolWhite.svg" download>SVG</a>
          </div>
        </div>
        <div class="mx-auto">
          <div class="bg-white p-8 rounded-lg">
            <img class="h-32" src="../../assets/images/asset/TokenDark.svg">
          </div>
          <div class="flex flex-row justify-between my-3 px-2">
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1 mr-4" href="/download/png/TokenDark.png" download>PNG</a>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1" href="/download/svg/TokenDark.svg" download>SVG</a>
          </div>
        </div>
        <div class="mx-auto">
          <div class="bg-black p-8 rounded-lg">
            <img class="h-32" src="../../assets/images/asset/TokenWhite.svg">
          </div>
          <div class="flex flex-row justify-between my-3 px-2">
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1 mr-4" href="/download/png/TokenWhite.png" download>PNG</a>
            <a class="bg-gray hover:bg-primary text-light rounded-full px-3 text-xs py-1" href="/download/svg/TokenWhite.svg" download>SVG</a>
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center justify-center">
        <a class="download-icon secondary-button-right text-xs px-8 py-3 hover:text-white flex flex-row" id="logo-asset-pack" href="/download/LogoAsset.zip" download>
          <img id="download_icon" class="img1" src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAA7UlEQVQ4je3UPyvFcRTH8Rf3UspNKMUsi8nsAZgNGJXRIk/BIzCw2G7d7RYpj8BuUDbJIMniTxkYRKf7VTdH7o/J4D19+5zT+/s7nW+/vtO9eT24QBNb37X1pyQzjrGU/kJUiX/RHxDFuu/xhhFslHMrdfYQPeI8pZykpEs0kFJesYKHruwI26mT+ofoFuupzCXWyvkKq2W8zxxjIWyjmEjlDgdYxhnuUrXDFBr1FGfaKfmCGO0Z07lUiSFM4ilEh1jC4g8f6DB2MBgbjtE2MYf98nUvFUWNcvFu/LNCdINZzJSHWKsoigtjm9fwDvXEJbxGU9J5AAAAAElFTkSuQmCC"/>
          <img id="download_icon_white" class="img2" width="18" height="18" src="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAA60lEQVQ4je3UP0pDQRDH8U9ED5BC4gEUu2AqS1v/NFZqbiB4BLH0HhY2gkUaxdILiBYK3kDBUkxjQBncwIMJ78UUVv5gi5n58d2d3fem9T781KCLUt6vs82nTFYnZSZoLqdm0z/oD0F1z3+J5bJGuMUL+snZAFpBtxJv4DG5iupaiy/5oxIPcZBcFVD8AtupwjMOK/ERnpKLrWBEa3ul9+tk4RyrZcOzVP3RejDq7misk5SZoNjpCwu5NLXawYgxcoclrOHtl5BFPOA1QJu4igADNA6oouhitxxiZzzYAnaKHlpTguJK7nGMm29AOSu6Np7wYQAAAABJRU5ErkJggg=="/>
          <div class="ml-2">Logo Asset Pack</div>
        </a>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'Logo',
  }
</script>

<style lang="scss" scoped>
  .line {
    transform: translateY(-50%);
    margin-left: 20px;
  }

  #logo-asset-pack {
    outline: none;
    border-radius: 2rem;
    text-align: center;
    border: 1px solid #C99B4D;
  }

  .download-icon {
    transform: translateY(50%);
    background: white;
    &:hover {
      background: #C99B4D;
      .img1 {
        display: none;
      }
      .img2 {
        display: block;
      }
    }
  }

  .row {
    width: fit-content;
  }

  .img1 {
    display: block;
  }
  .img2 {
    display: none;
  }

  .px-29 {
    padding-left: 7.25rem;
    padding-right: 7.25rem;
  }

  .rounded-5 {
    border-radius: 1.25rem;
  }

  .py-42-px {
    padding-top: 42px;
    padding-bottom: 42px
  }

  .h-28 {
    height: 7rem;
  }
</style>